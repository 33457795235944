import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import zoomSdk from "@zoom/appssdk";
import "../streamcss/Stream.css";
import { replaceAll } from "../utility/util";

function LiveStream(props) {
  const [streamData, setStreamData] = useState("");
  const [totalamount, setTotalAmount] = useState(0);
  const [target, setTarget] = useState(0);
  const formSettings = useSelector(
    (state) => state?.donation?.formSettings || {}
  );
  //const campRaisedSettings = useSelector((state) => state?.donation?.campaignRaisedDetails || {} );

 // const targetAmount = campRaisedSettings && Object.keys(campRaisedSettings).length && campRaisedSettings.CampaignTarget ? campRaisedSettings.CampaignTarget : 0;
 // const raisedAmount = campRaisedSettings && Object.keys(campRaisedSettings).length && campRaisedSettings.CampaignRaised ? campRaisedSettings.CampaignRaised : 0;
  
  //const streamDisplayFormat = formSettings.StreamFormat ? formSettings.StreamFormat : formSettings.PaymentOptionSettings.StreamMessageFormat;
  // zoomSdk.onMessage((event) => {
  //   const newStrm1 = JSON.parse(event.payload);
  //   let StreamMarquee = newStrm1.data;
  //   if(!Array.isArray(StreamMarquee)) StreamMarquee = JSON.parse(StreamMarquee);

  //   let totalAmount = StreamMarquee && StreamMarquee.length && StreamMarquee.reduce(
  //     (n, { amount }) => parseFloat(n) + parseFloat(amount),
  //     0
  //   );

  //   setStreamData(StreamMarquee);
  //   setTotalAmount(totalAmount);
  // });

  zoomSdk.onMessage((event) => {
    try {
      // Parse the main payload object
      const payload = JSON.parse(event.payload);
      let newData = JSON.parse(payload.data)
      // Extract streamData and campSettings from the parsed payload
      let StreamMarquee = newData.streamData;
  
      // Ensure StreamMarquee is an array; if not, parse it
      if (!Array.isArray(StreamMarquee)) {
        StreamMarquee = JSON.parse(StreamMarquee);
      }
  
      // Use campSettings as needed
      const campSettings = newData.campSettings;
   
      // Update state with the parsed data
      setStreamData(StreamMarquee);
      setTotalAmount(campSettings.CampaignRaised);
      setTarget(campSettings.CampaignTarget);
  
    } catch (error) {
      console.error("Error parsing message:", error);
    }
  });

  useLayoutEffect(() => {
    document.body.style.backgroundColor = "transparent";
  });

  

  return (
    <>
     <div className="virtual-stream-conatiner">
      <div
        className="stream-logo"
        // style={{ margin: "0.5rem 0 0 0", }}
      >
        <img
          src={`newimgs/givent_logo.png?${(new Date()).getTime()}`}
          style={{ width: "120px" , height: "35px"}}
          alt="I donated"
        />{" "}
      </div>
      <div className={`donatio-stream-marquee`}>
      
        {/* eslint-disable-next-line jsx-a11y/no-distracting-elements */}
        <marquee direction="right" heigt="100%">
          {/* {streamData ? <p className="donation-text">{streamData}</p> : null} */}
          {totalamount && target > 0 ? '':<br/>}
          {streamData && streamData.length ? streamData.map((stream)=>{
            let mapObj = {}, streamFormat='';
            let periodicity = stream.periodicity && stream.periodicity !=="One Time Only" ? '/'+stream.periodicity :'';
            if(formSettings.StreamFormat){
              streamFormat = formSettings.StreamFormat;

               mapObj = {
                "{Patron_First_Initial}": stream.firstName.charAt(0),
                "{Patron_First_Name}" : stream.firstName+' ',
                "{Patron_Last_Initial}": stream.lastName.charAt(0),
                "{Patron_Last_Name}" : stream.lastName,
                "{Amount}": formSettings.Currency+Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(parseFloat(stream.amount)).replace(/\.00$/, '')+periodicity
              }
            }else{
              streamFormat = formSettings.PaymentOptionSettings.StreamMessageFormat;
                mapObj = {
                  "{Patron Display Name}" : stream.firstName+' '+stream.lastName,
                  "{Donor Display Name}" : stream.firstName+' '+stream.lastName,
                  "{Amount}": formSettings.Currency+Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(parseFloat(stream.amount)).replace(/\.00$/, ''),
                  "{Date}" : stream.dDate
                }
            }
        
       
        const Lstring = streamFormat.replace("{Comments}","");
        const Lstream = replaceAll(Lstring, mapObj);
        return(
          <>
        <span className={`spacing donation-text`}>
          {" " +Lstream + `  `}
        </span>
        </>
        );
        }): (
          <> 
          <span className={`spacing donation-text`}>Loading... Please wait!</span>
          </>
        )}
        </marquee>

        <div className={`donation-remaining ${totalamount && target > 0 ? '':'hide-remaining'}`}>
          &nbsp;
          {totalamount && target > 0 
            ? 'Raised '+formSettings.Currency +
            Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(parseFloat(totalamount)).replace(/\.00$/, '') +
              ` of ${formSettings.Currency}${Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(parseFloat(target)).replace(/\.00$/, '')}`
            : null}{" "}
        </div>
      </div>
      </div>
    </>
  );
}

export default LiveStream;
