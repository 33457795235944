import React from "react";

function FooterSecuredBy(){
  return(
      <>
       <p className="footer-securedby"><span style={{fontSize:'0.75rem'}}>&#x1F512; </span><i>Secured and Powered by </i><strong>Click & Pledge</strong></p>
       
       </>
  );
};

export default FooterSecuredBy;