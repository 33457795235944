import React from "react";


const Loading = (props) => {

    // useEffect(() => {
    //     const confettiSettings = { target: 'my-canvas' };
    //     const confetti = new ConfettiGenerator(confettiSettings);
    //     confetti.render();
       
    //     return () => confetti.clear();
    //   }, [])

  return(
    <>
    {props.appoutofcontext ? (
      <h3 style={{margin:'auto',left:'33%', top:'45%',position:'absolute'}}>App can only be called with in a event</h3>
    ):(
      <h3 style={{margin:'auto',left:'45%', top:'45%',position:'absolute'}}>Loading...</h3>
    )}
   
    {/* <canvas id="my-canvas" style={{width:'100%',height:'100%'}}></canvas> */}
    {!props.appoutofcontext && (
      <div className='loader-container'>
          <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
    </div>
    )}
    
    </>
  );
};

export default Loading;